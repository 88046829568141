/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-30",
    versionChannel: "nightly",
    buildDate: "2024-09-30T00:06:06.081Z",
    commitHash: "81a2222f4af8e942205c1f427341c84c748294b0",
};
